import Helmet from "react-helmet"
import React from "react"
import AmoTyp from "@tightrope/amotyp"
import Data from "./data/data.json"
import SiteData from "branding/site-metadata.json"
import FooterlinksSingle from "@tightrope/footerlinks/footerlinks_single.js"



export default function ManualThankYouAmo() {
  return(
    <>
    <Helmet>
     <style type="text/css">{`
       .footerlinkssingle-module--br {
         position: absolute;
         bottom: 10px;
         left: 50%;
         transform: translateX(-50%);
       }

       img#amotyp-module--logo {
         height: 80px!important;
       }

       body {
         background-image: url(/img/template-searchbg.jpg)
       }

       .amotyp-module--amoContainer #amotyp-module--imsg {
         color: white;
       }
    }
    `}
    </style>
    <title>Thank you for downloading Freshy Search</title>
    </Helmet>

    <section>
      <AmoTyp data={Data} siteData={SiteData}></AmoTyp>
      <FooterlinksSingle></FooterlinksSingle>
    </section>
    </>
  )
}
